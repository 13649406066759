import { GrafixxItem, IGrafixxItem } from "../../../classes/Grafixx-item";
import { I18N } from "aurelia-i18n";
const moment = require("moment");
import { qChangeNotifier } from "../q-changeNotifier";
import { autoinject, bindable } from "aurelia-framework";
import { fhirEnums } from "../../../classes/fhir-enums";
import { HttpClient, HttpResponseMessage } from "aurelia-http-client";
import { DialogMessages } from "../../../services/DialogMessages";
import { FhirService } from "../../../services/FhirService";
import { ConfigService } from "../../../services/ConfigService";
import { RuntimeInfo } from "../../../classes/RuntimeInfo";
import {PatientItem} from "../../../classes/Patient/PatientItem";

@autoinject
export class qGrafixx {
    @bindable item: any;
    @bindable encounter: string;
    @bindable notifier: qChangeNotifier;

    /**
     * What should be displayed. indicated by initialCoding like grafixx_[summary|bedsore|supply|drainage|skin_lesions|pain|burn|other]
     */
    viewMode: string;
    _isLoading: boolean;
    get isLoading() {
        return this._isLoading;
    }

    set isLoading(value) {
        this._isLoading = value;
        qGrafixx.loadingObservations = value;
    }

    get grafixxItems(): IGrafixxItem[] {
        return GrafixxItem.Default;
    }

    i18n: I18N;
    observations: any[] = [];
    displayItems: any[] = [];
    notifierId: string = undefined;
    static _fhirService: FhirService;
    get fhirService() {
        if (!qGrafixx._fhirService)
            qGrafixx._fhirService = new FhirService();

        return qGrafixx._fhirService;
    }

    dialogMessages: DialogMessages;

    constructor(i18n: I18N, dialogMessages: DialogMessages) {
        this.i18n = i18n;
        this.dialogMessages = dialogMessages;
    }

    getImageName(item) {
        return `./images/bodies/icons/${item.imageName}.svg`;
    }

    notifierChanged() {
        this.notifierId = this.notifier.subscribe(async (e: any) => {
            // if (e.linkId !== this.item.linkId) return;
            if (!e.initialCoding || !e.initialCoding.code || e.initialCoding.code.indexOf("grafixx_") === -1) return;
            qGrafixx._observations = undefined;
            qGrafixx._observationUrl = undefined;
            await this.loadObservations();
        });
    }

    static _observations: any[] = undefined;
    static _observationUrl: string = undefined;
    static loadingObservations = false;

    private static waitForService(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (!this.loadingObservations) {
                resolve();
            } else {
                window.setTimeout(async () => {
                    await this.waitForService();
                    resolve();
                }, 50);
            }
        });
    }

    async loadObservations() {
        if (!this.encounter) return;
        await GrafixxItem.Init();
        await qGrafixx.waitForService();

        this.isLoading = true;

        let url = `${fhirEnums.ResourceType.observation}?${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}=${this.encounter}`;
        if (qGrafixx._observationUrl === url && qGrafixx._observations && qGrafixx._observations.length > 0) {
            this.observations = qGrafixx._observations;
            this.processObservations();
            return;
        }

        try {
            let result: any[] = <any[]>await this.fhirService.fetch(url);

            qGrafixx._observationUrl = url;
            qGrafixx._observations = result.filter(o => o.status !== fhirEnums.ObservationStatus.cancelled && o.status !== fhirEnums.ObservationStatus.enteredInError);
            this.observations = qGrafixx._observations;
            this.processObservations();
        } catch (e) {
            console.warn("GRAFIXX ERROR:", e);
            this.errorMessage = e.message || JSON.stringify(e);
        } finally {
            this.isLoading = false;
        }
    }

    processObservations() {
        this.calculateSums(this.observations);
        if (this.grafixxItems) {
            if (this.viewMode === "summary") {
                this.displayItems.forEach((item: any) => {
                    if (item.bodySite && item.bodySite.coding && item.bodySite.coding[0] && item.bodySite.coding[0].display) {
                        let body = item.bodySite.coding[0].display;
                        body = this.i18n.tr(body);

                        let descr = item.comment ? item.comment : '';
                        let date = moment(item.effectiveDateTime ? new Date(item.effectiveDateTime) : new Date()).format(RuntimeInfo.DateFormat);
                        let nr = item.identifier && item.identifier[0] && item.identifier[0] && item.identifier[0].value ? item.identifier[0].value : "-";
                        item.text = {
                            status: "generated",
                            div: `<td class="wound-index">${nr}</td>` +
                                `<td class="wound-position">${body}</td>` +
                                `<td>${descr}</td>` +
                                `<td>${typeof this.grafixxLevel !== "undefined" ? this.getLevelString(this.grafixxLevel, item) : ''}</td>` +
                                `<td class="wound-date">${date}</td>`
                        };
                    }
                });
            } else {
                let gfx = this.grafixxItems.find(o => o.type === this.viewMode);
                if (!gfx) return;

                this.displayItems = gfx && gfx.items ? gfx.items : [];

                if (gfx.items.length === 0 && PatientItem.SelectedPatient?.id) {
                    const dItem = {
                        subject: {reference: `Patient/${PatientItem.SelectedPatient.id}` },
                        resourceType: fhirEnums.ResourceType.observation,
                        text: {
                            status: "generated",
                            div: `<div xmlns="http://www.w3.org/1999/xhtml" class="text-muted">${this.i18n.tr('grafixx_no_' + this.viewMode)}</div>`
                        }
                    };

                    dItem[`${FhirService.FhirVersion > 3 ? 'encounter' : 'context'}`] = {reference: `Encounter/${PatientItem.SelectedPatient.encounterId}` };
                    this.displayItems.push(dItem);

                } else {
                    this.lines = [];
                    gfx.items.sort((a: any, b: any) => {
                        let id1 = parseInt(a.identifier && a.identifier[0] ? a.identifier[0].value : '0');
                        let id2 = parseInt(b.identifier && b.identifier[0] ? b.identifier[0].value : '0');
                        return id1 - id2;
                    });

                    gfx.items.forEach((observation: any) => {
                        let date = '?';
                        let location = '';
                        if (observation.bodySite?.coding?.[0]) {
                            let locationId = observation.bodySite.coding[0].display || observation.bodySite.coding[0].code;
                            if (locationId) location = this.i18n.tr(locationId);
                        }

                        if (observation.effectiveDateTime) {
                            date = moment(observation.effectiveDateTime).format(RuntimeInfo.DateFormat);
                        } else if (observation.effectivePeriod && observation.effectivePeriod.start) {
                            date = moment(observation.effectivePeriod.start).format(RuntimeInfo.DateFormat);
                        } else if (observation.effectivePeriod && observation.effectivePeriod.extension) {
                            let dateExt = observation.effectivePeriod.extension.find(o => o.url.indexOf('timeframe') > -1);
                            if (dateExt) {
                                if (typeof dateExt.valueInteger !== "undefined") {
                                    let days = String(dateExt.valueInteger);
                                    date = this.i18n.tr(`timeframe_${days}`);
                                }
                            }
                        }

                        let woundItem = {
                            id: observation.identifier && observation.identifier[0] ? observation.identifier[0].value : '-',
                            level2: '', // this.getLevelString(2, observation),
                            level3: '',// this.getLevelString(3, observation),
                            location: location,
                            date: date
                        };

                        if (observation.component) {
                            woundItem.level2 = observation.component[0] && observation.component[0].code ? this.i18n.tr(observation.component[0].code.text) : '';
                            woundItem.level3 = observation.component[1] && observation.component[1].code ? this.i18n.tr(observation.component[1].code.text) : '';
                        }

                        if (!woundItem.level2 && !woundItem.level3)
                            woundItem.level2 = location;

                        this.lines.push(woundItem);
                    });
                }

                this.displayItems = gfx.items;
            }
        }
    }

    lines: any[] = [];

    getLevelString(level: number, obs: any): string {
        let result: string = "";
        if (!qGrafixx.mapping) return result;

        if (typeof level === "undefined") {
            return result;
        }


        let wounds: any[] = qGrafixx.mapping["wounds"];
        let item = wounds.find(o => o.name.toUpperCase() === this.viewMode.toUpperCase());

        if (level >= 1) {
            if (level === 1 || !item.subtypes) result = this.i18n.tr(item.name);

            if (obs.component && item.subtypes && level >= 2) {
                let values = obs.component.map((m) => {
                    return m.code.text;
                });

                let level2Items = item.subtypes;
                let level2Item = level2Items.find(o => o.name === values[0]);
                result += this.i18n.tr(level2Item.name);

                if (!result) {
                    result += values[level - 1] ? String(values[level - 1]) : '';
                }

                if (level >= 3 && values[1]) {
                    if (level2Item && level2Item.items) {
                        let level3Items = level2Item.items;
                        let level3Item = values[1];

                        result += this.i18n.tr(level3Item);
                    }
                }
            } else if (level >= 2) result = "";

            return result;
        }

        return "";
    }

    errorMessage: string = "";

    calculateSums(observations: any[]) {
        if (!this.grafixxItems) return;
        for (let i = 0; i < this.grafixxItems.length; i++) {
            let item = this.grafixxItems[i];
            if (item) {
                item.items = observations.filter(o =>
                    (o.status !== 'cancelled' && o.status !== 'entered-in-error')
                    && (o.category && o.category[0] && o.category[0].text === item.type)
                );

                item.sum = item.items.length;
            }
        }

        // console.debug("grafixxItems:", this.grafixxItems);
        this.isLoading = false;
    }

    async encounterChanged(id: string) {
        this.loadObservations();
    }

    grafixxLevel: number = undefined;

    itemChanged(newItem?: any) {
        if (!newItem) return;
        if (newItem.initialCoding && newItem.initialCoding.code && newItem.initialCoding.code.indexOf('_') > -1) {
            let code = newItem.initialCoding.code;
            this.viewMode = code.substr(code.indexOf('_') + 1); // has to be handeled this way because of "skin_lesions"
        }

        if (newItem.extension) {
            let levelItem = newItem.extension.find(o => o.url.endsWith("grafixx-level"));
            if (levelItem) {
                this.grafixxLevel = levelItem.valueInteger ? levelItem.valueInteger : parseInt(levelItem.valueString);
            }
        }

        this.processObservations();
    }

    static mapping: any;

    /** load the mappings from body-ma-wounds.json to be able to display the different Grafixx-levels */
    static loadBodyMap(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (qGrafixx.mapping) {
                resolve(qGrafixx.mapping);
                return;
            }

            new HttpClient().get("config/body-map-wounds.json?_=" + new Date().valueOf())
                .then((msg: HttpResponseMessage) => {
                    qGrafixx.mapping = JSON.parse(msg.response);
                    resolve(qGrafixx);
                })
                .catch(e => {
                    let err = DialogMessages.HttpErrorToString(e.replace("<br />", "\n"));
                    console.warn(err);
                    reject(err);
                });
        });
    }

    async attached() {
        if (ConfigService.IsTest) return;

        await this.i18n.i18next.loadResources();
        await qGrafixx.loadBodyMap();
    }
}
